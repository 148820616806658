import React from "react";
import styled from "styled-components";
import { respondTo } from "../styles/respond-to";

const EqualOpportunityStyled = styled.div`
  text-align: center;
  font-size: 0.8rem;
  margin-bottom: 1rem;
  font-style: italic;
  span {
    font-family: RalewaySemiBold;
  }

  ${respondTo.md`
    width: 80%;
    margin: 0 auto 1rem;
  `}
`;

const EqualOpportunity = () => {
  return (
    <EqualOpportunityStyled>
      <span>Equal Opportunity Employment Program</span> - 
      Auxiliary Aids and Services are
      available to individuals with disabilities
    </EqualOpportunityStyled>
  );
};

export default EqualOpportunity;
