import React from "react";
import Footer from "./footer";
import "normalize.css";
import GlobalStyles from "../styles/global";
import Typography from "../styles/typography";
import Mobile from "../styles/mobile";
import CountyServicesStyles from "../styles/county-services";
import SimpleReactLightbox from "simple-react-lightbox";

import { AppProvider } from "../context";

const Layout = ({ children }) => {
  return (
    <>
      <AppProvider>
        <SimpleReactLightbox>
          <GlobalStyles />
          <Typography />
          <Mobile />
          <CountyServicesStyles />
          {children}
          <Footer />
        </SimpleReactLightbox>
      </AppProvider>
    </>
  );
};

export default Layout;
