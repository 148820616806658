import React from "react";
import { Link } from "gatsby";
import styled from "styled-components";
import { FaFacebook } from "react-icons/fa";

const StyledCredits = styled.div`
  background-color: var(--palette-blue);
`;

const CreditsContent = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 80vw;
  margin: 0 auto;
  font-size: 0.8rem;
  font-style: italic;
  color: white;
  a {
    color: white;
  }
`;

const SocialMedia = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  color: white;
  font-size: 1.5rem;

  a {
    display: block;
    margin: 0 1rem;
  }
`;

const Credits = () => {
  return (
    <StyledCredits>
      <CreditsContent>
        <p>
          &copy; 2021 Workforce Solutions for North Central PA - All rights reserved -{" "}
          <Link to="/privacy">Privacy</Link>
        </p>

        <SocialMedia>
          <a href="https://www.facebook.com/worforcesolutionspa/" target="_new">
            <FaFacebook />
          </a>
        </SocialMedia>
      </CreditsContent>
    </StyledCredits>
  );
};

export default Credits;
