import { createGlobalStyle } from "styled-components";
import { respondTo } from "./respond-to";

const CountyServicesStyles = createGlobalStyle`
  .services-body {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    ${respondTo.sm`
      flex-direction: column;
    `}

    ${respondTo.md`
      flex-direction: column;
    `}
  }


  .services-col {
    width: 48%;

    ${respondTo.sm`
      width: 100%;
    `}

    ${respondTo.md`
      width: 100%;
    `}

    a {
      color: var(--text);

      &:hover {
        color: black;
      }
    }

    h4 {
      font-family: RalewayLight;
      font-size: 1.5rem;
      color: var(--text);
      margin: 1rem 0 0;
    }

    table {
      width: 100%;

      th, td {
        width: 30%;
        text-align: left;
        padding: 0 0 1rem;

        ${respondTo.sm`
          width: 40%;
        `}

        ${respondTo.md`
          width: 40%;
        `}

        &:first-child {
          width: 70%;
          font-weight: bold;

          ${respondTo.sm`
            width: 60%;
            padding-right: 1rem;
          `}

          ${respondTo.md`
            width: 60%;
            padding-right: 1rem;
          `}
        }
      }

      th {
        text-transform: uppercase;
        color: var(--palette-brown);
      }

      td {
        vertical-align: top;
        &:first-child: {
          font-weight: bold;
        }
      }
    }
  }

  .service-item {
    display: flex;
    width: 50%;
    margin-bottom: 0.8rem;
    font-size: 0.9rem;

    ${respondTo.sm`
      width: 100%;
    `}

    ${respondTo.md`
      width: 100%;
    `}

    .name {
      width: 70%;
      margin-right: 1rem;
      font-family: RalewaySemiBold;

      ${respondTo.sm`
        width: 50%;
      `}
    }

    a {
      color: var(--text);
      text-decoration: none;

      &:hover {
        color: black;
      }
    }
  }

  .service-item-single-col {
    width: 100%;
  }

  .service-heading {
    text-transform: uppercase;
    font-family: RalewaySemiBold;
    color: var(--palette-brown);
    margin-top: 1rem;
  }


`;

export default CountyServicesStyles;
