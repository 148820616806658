import React, { useState, useContext } from "react";

const AppContext = React.createContext();

const AppProvider = ({ children }) => {
  // Mobile navigation
  const [mobileNav, setMobileNav] = useState(false);

  const showMobileNav = () => {
    setMobileNav(true);
  };

  const hideMobileNav = () => {
    setMobileNav(false);
  };

  const toggleMobileNav = () => {
    setMobileNav(!mobileNav);
  };

  // Careerlink Modal
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = (e) => {
    e.preventDefault();
    setIsModalOpen(true);
  };

  const closeModal = (e) => {
    e.preventDefault();
    setIsModalOpen(false);
  };

  const hello = "Hello";

  return (
    <AppContext.Provider
      value={{
        toggleMobileNav,
        mobileNav,
        showMobileNav,
        hideMobileNav,
        isModalOpen,
        openModal,
        closeModal,
        hello
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

export { AppContext, AppProvider };

export const useGlobalContext = () => {
  return useContext(AppContext);
};
