import { createGlobalStyle } from "styled-components";
import { respondTo } from "./respond-to";

const GlobalStyles = createGlobalStyle`
  html, body {
    // overflow-x: hidden;
    // position: relative;
  }

  :root {
    --gray: #657679;
    --gray-light: #E8DFDF;
    --text: #696363;
    --blue: #5A82DB;
    --blue-dark: #3063D5;
    --palette-brown: #975D21;
    --palette-dark-brown: #61401E;
    --palette-blue: #3A6181;
    --palette-light-blue: #ADCED1;
    --palette-very-light-gray: #FAF4F4;
    --palette-very-light-brown: #F2E9DF;
    --yellow-hover: #EFF470;
  }

  * { box-sizing: border-box }

  .button, button {
    display: inline-block;
    background-color: white;
    color: var(--palette-brown);
    border: 2px solid var(--palette-brown);
    padding: 0.6rem 1rem;
    border-radius: 10px;
    text-transform: uppercase;
    font-family: RalewaySemiBold;
    font-size: .8rem;
    letter-spacing: 1px;
    cursor: pointer;
    transition: all 0.2s;
    &:hover {
      color: white;
      background-color: var(--palette-brown);
    }

    &:active {
      background-color: black;
    }
  }

  .button {
    text-decoration: none;
  }

  .button-white {
    background-color: transparent;
    color: white;
    border: 2px solid white;
    display: block;
    &:hover {
      background-color: var(--palette-brown);
    }
    &:active {
      background-color: black;
    }
  }

  .button-brown {
    background-color: var(--palette-brown);
    color: white;
    &:hover {
      background-color: var(--palette-dark-brown);
      border: 2px solid var(--palette-dark-brown);
    }
    &:active {
      background-color: black;
    }
  }

  .center-button-container {
    text-align: center;
  }

  main {
    width: 80vw;
    margin: 0 auto;

    ${respondTo.sm`
      width: 90%;
    `}
  }

  section {
    margin-bottom: 2rem;
    padding-bottom: 1rem;
  }

  .page-text {
    width: 80%;
    margin: 2rem auto;

    ol, ul {
      padding: 0 0 0 1rem;
      li {
        margin-bottom: 1rem;
      }
    }

    ${respondTo.sm`
      width: 90%;
    `}
  }

  .page-intro {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 2rem 0;

    ${respondTo.sm`
      flex-direction: column;
      justify-content: flex-start;
      margin: 1rem 0 0;
    `}

    ${respondTo.md`
      flex-direction: column;
      justify-content: flex-start;
      margin: 0;
    `}

    .video-reorder {
      ${respondTo.sm`
        order: 2;
      `}

      ${respondTo.md`
        order: 2;
      `}
    }

    .intro-title {
      width: 49%;
      align-self: flex-start;

      ${respondTo.sm`
        width: 100%;
      `}

      ${respondTo.md`
        width: 100%;
      `}

      h2 {
        font-size: 1.6rem;
        line-height: 2.2rem;
        margin: 0 0 1rem;

        ${respondTo.md`
          margin-top: 1rem;
        `}
      }

      p {
        font-family: RalewayLight;
        font-size: 1.6rem;
        margin: 0;
        line-height: 2.2rem;

        ${respondTo.md`
          width: 100%;
        `}
      }

      p.title-small {
        margin-top: 1rem;
        font-size: 1rem;
        line-height: 1.5rem;
      }
    }

    .intro-text {
      width: 49%;
      align-self: flex-end;

      ${respondTo.sm`
        width: 100%;
        margin-bottom: 1rem;
      `}

      ${respondTo.md`
        width: 100%;
        margin-bottom: 1rem;
      `}

      p {
        margin: 0;
      }
    }

    .intro-text-home {
      align-self: flex-start;

      h2 {
        font-family: RalewayLight;
        font-size: 3rem;
        color: var(--palette-brown);
        margin-bottom: 1rem;
      }

      h3 {
        ${respondTo.sm`
          margin: 0 0 .5rem;
        `}
      }

      p {
        margin-bottom: 2rem;

        ${respondTo.sm`
          margin-bottom: 0;
        `}
      }
    }

    .intro-text-video {
      width: 48%;
      align-self: center;

      ${respondTo.sm`
        width: 100%;
      `}

      ${respondTo.md`
        width: 100%;
      `}
    }
  }


  .industry-links {
    width: 49%;

    ${respondTo.sm`
      width: 100%;
    `}

    ${respondTo.md`
      width: 100%;
    `}

    a {
      background-color: white;
      display: block;
      text-transform: uppercase;
      text-decoration: none;
      color: var(--text);
      font-size: 1rem;
      padding: .7rem .5rem;
      border-left: 5px solid var(--palette-brown);
      border-top: 1px solid var(--palette-brown);
      border-right: 1px solid var(--palette-brown);
      border-bottom: 1px solid var(--palette-brown);
      margin: .6rem 0;
      box-shadow: 2px 2px 4px rgba(0, 0, 0, .2);

      &:hover {
        box-shadow: 3px 3px 5px rgba(0, 0, 0, .3);
        background-color: var(--palette-very-light-brown);
      }
      &:active {
        box-shadow: none;
        background-color: var(--palette-very-light-brown);
      }
    }
  }


  .county-employers {

    width: 100%;
    background-color: var(--palette-very-light-brown);
    padding: 0;
    margin-bottom: 2rem;

    section {
      width: 80vw;
      margin: 0 auto;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: flex-start;

      ${respondTo.sm`
        flex-direction: column;
        width: 95%;
      `}

      .employers-table-container {
        width: 68%;

        ${respondTo.sm`
          width: 100%;
        `}

        h2 {
          margin-top: 2rem;
        }
      }

      .employers-photos {
        width: 30%;

        ${respondTo.sm`
          width: 100%;
        `}
      }
    }
  }


  .career-pathways-container {
    width: 100%;
    margin-bottom: 0;
    background-color: var(--palette-very-light-brown);

    ${respondTo.sm`
      padding-top: 2rem;
    `}

    ul {
     margin: 0; 
     padding: 0;
     list-style: none;

     li {
       line-height: 1.2em;
       margin-bottom: .4rem;
       &:last-child {
         margin-bottom: 0;
       }
     }
    }

    h3 {
      margin-top: 0;
    }

    h4 {
      text-transform: uppercase;
      color: var(--palette-brown);
      margin: 1.4rem 0 .3rem;
    }

    p {
      margin: 0;
    }

    .pathway-mobile-row {
      width: 90%;
      margin: auto;
      border-bottom: 4px solid white;
      padding-top: 1rem;
      padding-bottom: 1rem;

      &:last-child {
        border-bottom: none;
      }

      ${respondTo.md`
        width: 70%;
      `}
    }
  }

  table {
    font-size: 0.9rem;
    background-color: white;
    padding: 1rem 0;
    width: 100%;
  }

  .employers {
    th {
      width: 50%;
      text-align: left;
      padding: 0 1rem 1rem;
      text-transform: uppercase;
      font-weight: bold;
      color: var(--palette-brown);
    }

    td {
      width: 50%;
      padding: 0 1rem 1.5rem;
      vertical-align: top;
    }
  }


  .career-pathways {
    width: 80vw;
    margin: 0 auto;
    background-color: transparent;

    th {
      width: 23%;
      text-align: left;
      text-transform: uppercase;
      font-weight: bold;
      color: var(--palette-brown);
      padding: 0 1rem 1rem;

      &:last-child {
        text-align: center;
      }
    }

    td {
      width: 23%;
      padding: 0 1rem 1.5rem;
      vertical-align: top;

      &:first-child {
        font-weight: bold;
      }
      &:last-child {
        text-align: center;
      }
    }
  }

  .explore-photos {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    grid-gap: 1rem;
    margin-bottom: 2rem;

    .gatsby-image-wrapper {
      // width: 200px;
      // margin: .5rem;
      cursor: pointer;
    }
  }

  form {

    border-left: 6px solid var(--palette-brown);
    padding-left: 3rem;

    ${respondTo.sm`
      border-left: none;
      padding-left: 0;
    `}

    label {
      text-transform: uppercase;
      font-family: RalewaySemiBold;
      font-size: .8rem;
      color: var(--palette-dark-brown);
      display: block;
      margin-bottom: .4rem;
    }

    input[type="text"],
    input[type="email"],
    textarea {
      display: block;
      width: 100%;
      font-size: 1.2rem;
      padding: 4px;
      border: 1px solid var(--gray);
      border-radius: 4px;
      margin-top: .5rem;
    }

    textarea {
      height: 10rem;
    }

    .form-row {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin-bottom: 1rem;

      ${respondTo.sm`
        flex-direction: column;
      `}

      > div {
        width: 48%;

        ${respondTo.sm`
          width: 100%;
        `}
      }
    }

    .row-wide {
      flex-direction: column;
      justify-content: flex-start;
    }

    .row-wide-centered {
      justify-content: center;
    }
  }


  .background-wrapper {
    width: 100%;
    background-color: var(--palette-very-light-brown);
    padding: 0;
    margin-bottom: 2rem;
    padding-top: 2rem;

    section {
      margin-top: 0;
    }
  }

`;

export default GlobalStyles;
