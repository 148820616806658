import { createGlobalStyle } from "styled-components";

import ralewayLight from "../assets/fonts/Raleway/Raleway-Light.ttf";
import ralewayRegular from "../assets/fonts/Raleway/Raleway-Regular.ttf";
import ralewaySemiBold from "../assets/fonts/Raleway/Raleway-SemiBold.ttf";
import ralewayBold from "../assets/fonts/Raleway/Raleway-Bold.ttf";

const Typography = createGlobalStyle`
  @font-face {
    font-family: RalewayLight;
    src: url(${ralewayLight});
  }

  @font-face {
    font-family: RalewayRegular;
    src: url(${ralewayRegular});
  }

  @font-face {
    font-family: RalewaySemiBold;
    src: url(${ralewaySemiBold});
  }

  @font-face {
    font-family: RalewayBold;
    src: url(${ralewayBold});
  }

  html {
    font-family: RalewayRegular, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    color: var(--text);
    font-size: 18px;
  }

  h1, h2, h3, h4, h5, h6 {
    font-weight: normal;
  }

  h1 {
    font-size: 3rem;
    font-family: RalewaySemiBold;
  }

  h2 {
    font-size: 1.6rem;
    font-family: RalewaySemiBold;
    margin-top: 0;
  }

  h3 {
    font-size: 1.2rem;
    font-family: RalewaySemiBold;
  }

  h4 {
    font-size: 1.1rem;
    font-family: RalewayBold;
    color: var(--blue);
  }

  p {
    line-height: 1.5rem;
  }

  .note {
    background-color: yellow;
    display: inline-block;
    padding: 0 4px;
    font-style: italic;
  }

  p.temp {
    background-color: lightyellow;
    border: 1px dotted orange;
  }

  .social-media-icon {
    width: 20px;
    height: 20px;
    &:hover {
      fill: var(--yellow-hover);
    }
  }

  ul {
    li {
      line-height: 1.5rem;
    }
  }

  figure {
    padding: 0;
    margin: 0;

    figcaption {
      font-size: .8rem;
      font-style: italic;
      margin-top: .7rem;
    }
  }

  .pacareerlink {
    font-family: RalewayBold;
  }

`;

export default Typography;
