import {createGlobalStyle} from 'styled-components'

const Mobile = createGlobalStyle`
  .mobile-nav-show {
    transform: translateX(-0%);
  }

  .mobile-nav-hide {
    transform: translateX(-100%);
  }

  .menu-btn {
    display: block;
    border: none;
    border-color: transparent;
    background: transparent;
    font-size: 1.5rem;
    line-height: 0;
    color: white;
    margin: 0;
    padding: 0;
    transition: all 0.3s ease-out;

    &:hover {
      color: white;
      background-color: transparent;
    }
  }

  .menubar-btn {
    padding: .5rem;
  }

  .menubar-btn-active {
    transform: rotate(90deg);
  }
`

export default Mobile
