import React from "react";
import styled from "styled-components";
import { Link } from "gatsby";
import Credits from "./credits";
import EqualOpportunity from "./equal-opportunity";
import LogoBlue from "../assets/images/logo-blue.svg";
import { respondTo } from "../styles/respond-to";

const FooterStyles = styled.footer`
  padding: 0;
  color: var(--palette-brown);
  width: 100vw;
  margin: 0 auto;
  border-top: 6px solid var(--palette-blue);
  background-color: var(--palette-very-light-gray);
`;

const FooterContent = styled.div`
  width: 80vw;
  margin: 2rem auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;

  ${respondTo.sm`
    flex-direction: column;
    width: 95%;
  `}

  ${respondTo.md`
    flex-direction: row;
    flex-wrap: wrap;
    width: 80%;

    .tablet-col {
      width: 50%; 
    }
  `}

  a {
    color: var(--palette-brown);
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }

  .logo-address-container {
    .address {
      margin: 2rem auto 1rem;
      text-align: center;
    }

    ${respondTo.sm`
      margin: auto;
    `}

    ${respondTo.md`
      width: 100%;
      margin: auto;

      .logo-footer {
        margin: auto;
        display: block;
      }

      .address {
        margin: 1rem auto 2rem;
      }
    `}
  }

`;

const StyledNavCol = styled.div`
  display: flex;
  flex-direction: column;

  ${respondTo.sm`
    margin-bottom: 1rem;
    padding: 0 2rem;
  `}

  ${respondTo.md`
    margin-bottom: 1rem;
    padding: 0 2rem;
  `}

  a {
    display: block;
    margin-bottom: 0.6rem;
    color: var(--palette-brown);
    font-family: RalewayBold;
    text-transform: uppercase;
    text-decoration: none;
    font-size: 0.8rem;
    &:hover {
      text-decoration: underline;
    }
  }

  .sublinks {
    display: flex;
    flex-direction: column;
    a {
      font-family: RalewayRegular;
      text-transform: none;
    }
  }
`;

const Footer = () => {
  return (
    <FooterStyles>
      <FooterContent>
        <div className="logo-address-container">
          <img className="logo-footer" src={LogoBlue} alt="Discover PA Six" />

          <p className="address">
            425 Old Kersey Road
            <br />
            Kersey, PA 15846
            <br />
            <Link to="mailto:info@ncwdb.org">info@ncwdb.org</Link>
            <br />
            (814) 245-1835
          </p>
        </div>

      <div className="tablet-col">
        <StyledNavCol>
          <Link to="/work">Work</Link>
          <div className="sublinks">
            <Link to="/work/advanced-manufacturing">
              Advanced Manufacturing
            </Link>
            <Link to="/work/agriculture-food-production">
              Agriculture &amp; Food Production
            </Link>
            <Link to="/work/bio-medical">Bio Medical</Link>
            <Link to="/work/building-construction">
              Building &amp; Construction
            </Link>
            <Link to="/work/business-services">Business Services</Link>
            <Link to="/work/education">Education</Link>
            <Link to="/work/energy">Energy</Link>
            <Link to="/work/health-care">
              Health Care &amp; Social Assistance
            </Link>
            <Link to="/work/hospitality-leisure-entertainment">
              Hospitality, Leisure and Entertainment
            </Link>
            <Link to="/work/logistics-transportation">
              Logistics &amp; Transportation
            </Link>
            <Link to="/work/real-estate-finance-insurance">
              Real Estate, Finance &amp; Insurance
            </Link>
            <Link to="/work/wood-publishing">
              Wood Products &amp; Publishing
            </Link>
          </div>
        </StyledNavCol>
      </div>

      <div className="tablet-col">
        <StyledNavCol>
          <div>
            <Link to="/live">Live</Link>
            <div className="sublinks">
              <Link to="/counties/cameron">Cameron County</Link>
              <Link to="/counties/clearfield">Clearfield County</Link>
              <Link to="/counties/elk">Elk County</Link>
              <Link to="/counties/jefferson">Jefferson County</Link>
              <Link to="/counties/mckean">McKean County</Link>
              <Link to="/counties/potter">Potter County</Link>
            </div>
          </div>
        </StyledNavCol>

        <StyledNavCol>
          <Link to="/discover">Discover</Link>
        </StyledNavCol>

        <StyledNavCol>
          <Link to="/testimonials">Testimonials</Link>
          <Link to="/about">About</Link>
          <Link to="/connect">Connect</Link>
        </StyledNavCol>

      </div>

      </FooterContent>

      <EqualOpportunity />
      <Credits />
    </FooterStyles>
  );
};

export default Footer;
